import React, { ComponentPropsWithoutRef, useEffect } from 'react';
import { MultipleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import {
  SysLink,
  Entry,
  EntryProps,
  PlainClientAPI
} from 'contentful-management';
import { findAsync } from '../../utilities';
import { client, gql } from '../services/graphql';

type Action = Parameters<
  NonNullable<
    ComponentPropsWithoutRef<typeof MultipleEntryReferenceEditor>['onAction']
  >
>[0];

interface PageSectionsFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

interface Icon {
  title: string;
  id: string;
  file?: {
    url: string;
  };
}
interface Feature {
  id: string;
  description: string;
  title: string;
  image?: Icon;
}

interface Detail {
  features: Array<Feature>
}

const PageSectionsField = ({ sdk, cma }: PageSectionsFieldProps) => {
  useEffect(() => {
    sdk.window.startAutoResizer({ absoluteElements: true });
  });

  const getFeatureCards = (productFeatures: Feature[]) => {
    return Promise.all(
      productFeatures.map(async (feature) => {
        const existingCard = await cma.entry.getMany({
          query: {
            content_type: 'textCard',
            'fields.header': feature.title
          }
        });

        if (existingCard.items.length) {
          return {
            sys: {
              type: 'Link',
              linkType: 'Entry',
              id: existingCard.items[0].sys.id
            }
          };
        } else {
          const assets = await cma.asset.getMany({
            query: {
              mimetype_group: 'image',
              'metadata.tags.sys.id[all]': 'icon',
              select: 'fields.title',
              limit: 1000
            }
          });
          const featureIcon = assets.items
            .filter((asset) => {
              return asset.fields.title['en-US'] === feature.title;
            })
            .map((icon) => ({
              sys: {
                type: 'Link',
                linkType: 'Asset',
                id: icon.sys.id
              }
            }))?.[0];

          const newCard = await cma.entry.create(
            {
              contentTypeId: 'textCard'
            },
            {
              metadata: {
                tags: [
                  {
                    sys: {
                      type: 'Link',
                      linkType: 'Tag',
                      id: 'productFeature'
                    }
                  }
                ]
              },
              fields: {
                title: {
                  [sdk.locales.default]: `Product Feature - ${feature.title}`
                },
                header: {
                  [sdk.locales.default]: feature.title
                },
                subheader: {
                  [sdk.locales.default]: {
                    nodeType: 'document',
                    data: {},
                    content: [
                      {
                        nodeType: 'paragraph',
                        data: {},
                        content: [
                          {
                            nodeType: 'text',
                            data: {},
                            marks: [],
                            value: feature.description
                          }
                        ]
                      }
                    ]
                  }
                },
                icon: {
                  [sdk.locales.default]: featureIcon
                },
                backgroundColor: {
                  [sdk.locales.default]: 'transparent'
                }
              }
            }
          );

          cma.entry.publish({ entryId: newCard.sys.id }, newCard);

          return {
            sys: {
              type: 'Link',
              linkType: 'Entry',
              id: newCard.sys.id
            }
          };
        }
      })
    );
  };

  const handleLinkProduct = async (value: Array<Entry>, entity: EntryProps) => {
    const newPageItems: SysLink[] = [];

    // Get product PLM data from endpoint
    const productData = await client(sdk.ids.environment).request(
      gql`
        query getProduct($cmsId: ID!) {
          productByCmsId(id: $cmsId) {
            title
            categories
            details {
              features {
                id
                description
                title
                image {
                  title
                  id
                  file {
                    url
                  }
                }
              }
            }
            demographics {
              gender
            }
          }
        }
      `,
      { cmsId: entity.sys.id }
    );

    // Compile all product features and filter out features with incomplete data
    const productFeatures: Array<Feature> = Array.from(productData?.productByCmsId?.details?.reduce((features: Set<Feature>, detail: Detail) => {
      detail?.features.forEach((feature: Feature) => {
      if (feature.title && feature.description) features.add(feature)
    })
    return features;
    }, new Set()));

    if (productFeatures?.length) {
      const productTitle: string = await cma.entry
        .get({ entryId: sdk.entry.getSys().id })
        .then((e) => e.fields.title[sdk.locales.default]);

      // Get or create Text Cards for top product features
      const cards = await getFeatureCards(productFeatures.slice(0, 3));

      // Create Featured Tech Card Container & link related feature cards
      const entry = await cma.entry
        .create(
          {
            contentTypeId: 'cardContainer'
          },
          {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'productFeaturedTech'
                  }
                }
              ]
            },
            fields: {
              title: {
                [sdk.locales.default]: `${productTitle} Featured Tech`
              },
              backgroundColor: {
                [sdk.locales.default]: 'grey20'
              },
              cardWidth: {
                [sdk.locales.default]: 'L'
              },
              cards: {
                [sdk.locales.default]: cards
              }
            }
          }
        )
        .then((e) => cma.entry.publish({ entryId: e.sys.id }, e));

      newPageItems.push({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: entry.sys.id
        }
      });
    }

    // Excludes donation module from being added to products like giftcards, bags, and other addon items.
    if (productData?.productByCmsId.demographics?.length) {
      const { gender } = productData.productByCmsId.demographics[0]
      const { categories } = productData.productByCmsId;

      // Find all donation modules for product categories
      // NOTE: Categories are sorted A-Z to properly match tags for multicategory packs
      const donationModules: Array<EntryProps> = await cma.entry
      .getMany({ query: { 'metadata.tags.sys.id[all]': `donation,category-${categories.sort().join('-').toLowerCase()}` } })
      .then((r) => r.items || []);

      // Isolate relevant module
      const relevantModule = donationModules.length > 1 ? donationModules.find((mod) => mod.metadata?.tags.some((tag) => tag.sys.id === `gender-${gender.toLowerCase()}`)) : donationModules[0];

      // ID for relevent module or default donation module
      const entityId = relevantModule?.sys.id || await cma.entry.getMany({ query: { 'metadata.tags.sys.id[all]': `donation,category-socks,gender-unisex`}}).then((r) => r.items[0].sys.id) 

      if (entityId) {
        newPageItems.push({
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: entityId
          }
        });
      }
    }

    if (newPageItems.length) {
      sdk.field.setValue([...(value || []), ...newPageItems]);
    }
  };

  const handleAction = async (action: Action) => {
    if (
      action.type === 'create_and_link' ||
      action.type === 'select_and_link'
    ) {
      const actionEntityIsProduct =
        action.entityData.sys.contentType.sys.id === 'product';

      if (actionEntityIsProduct) {
        const values = sdk.field.getValue();
        const hasProductLink = await findAsync(
          values.filter(
            (link: EntryProps) => link.sys.id !== action.entityData.sys.id
          ),
          async (item: EntryProps) =>
            await cma.entry.get({ entryId: item.sys.id }).then((e) => {
              if (e.sys.contentType.sys.id === 'product') return true;
              return false;
            })
        );

        // If first product was added, add dynamic modules.
        if (!hasProductLink)
          return await handleLinkProduct(values, action.entityData);
      }
    }
  };

  return (
    <div style={{userSelect: 'none'}}>
      <MultipleEntryReferenceEditor
        viewType="link"
        sdk={sdk}
        hasCardEditActions
        isInitiallyDisabled={false}
        parameters={{
          instance: { showCreateEntityAction: true, showLinkEntityAction: true }
        }}
        onAction={handleAction}
      />
    </div>
  );
};

export default PageSectionsField;
