import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  ComponentPropsWithoutRef
} from 'react';
import { MultipleEntryReferenceEditor } from '@contentful/field-editor-reference';
import {
  SettingsIcon,
  MoreHorizontalIcon,
  WarningIcon
} from '@contentful/f36-icons';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { EntryProps, PlainClientAPI, SysLink } from 'contentful-management';
import { debounce } from 'lodash';
import { Status, Item } from './Variant.sku';
import { patchEntryFieldReferences } from '../../utilities';
import { VARIANTS_LIMIT } from '../../shared/constants';

import {
  Autocomplete,
  Card,
  Paragraph,
  Text,
  SkeletonContainer,
  SkeletonBodyText,
  Button,
  Stack,
  Checkbox,
  Asset,
  Menu,
  IconButton,
  Badge,
  Box,
  Flex,
  BadgeVariant,
  Tooltip,
  MenuDivider,
  Note,
  SectionHeading
} from '@contentful/f36-components';
import { client, gql } from '../services/graphql';
import { useDynamicHeight } from '../Field.hooks';
import { renderVariantItem, generateVariantTitle } from '../../utilities';
import DeletedEntryCard from '../DeletedEntryCard';

import './Product.variants.css';

const sillyQuotes = [
  'did you feed your cats today?',
  'when will we re-release leggings already???',
  'you look nice today!',
  'have a good day!',
  "we don't talk about Bruno, no, no, no"
];

interface ProductVariantsFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EntryPropsWithCustomMetaData = EntryProps & {
  custom: Record<string, any>;
};

enum BulkAction {
  Publish = 'Publish',
  Replicate = 'Replicate',
  ToggleActiveStatus = 'ToggleActiveStatus'
}

enum ReplicateActions {
  Swatch = 'Swatch',
  VariantImages = 'VariantImages'
}

interface ReplicateActionData {
  actions: Array<ReplicateActions>;
  fromId: string;
}

enum ToggleActiveStatusType {
  Active = 'Active',
  Inactive = 'Inactive'
}

interface ToggleActiveStatusData {
  type: ToggleActiveStatusType
}

interface PublishAction {
  action: BulkAction.Publish
}

interface ReplicateAction {
  action: BulkAction.Replicate,
  data: ReplicateActionData
}

interface ToggleActiveStatusAction {
  action: BulkAction.ToggleActiveStatus,
  data: ToggleActiveStatusData
}

type BulkActionParams = PublishAction | ReplicateAction | ToggleActiveStatusAction;

type CustomCardRendererProps = Parameters<
  NonNullable<
    ComponentPropsWithoutRef<
      typeof MultipleEntryReferenceEditor
    >['renderCustomCard']
  >
>;

const netsuiteInventoryInfoLabel = (netsuiteInventory:number|undefined|null = undefined):string => {
  return `Netsuite (${netsuiteInventory || 0})`;
}

const shopifyInventoryLabel = (shopifyInventory:number|undefined|null = undefined):string => {
  return `Shopify (${shopifyInventory || 0})`;
}

const inventoryInfo = (
  args: {
    shopifyInventory: number | undefined,
    netsuiteInventory: number | undefined,
  } = { shopifyInventory: undefined, netsuiteInventory: undefined }
): string => {
  const inventoryLabels: Array<string> = [];
  inventoryLabels.push(shopifyInventoryLabel(args.shopifyInventory));
  inventoryLabels.push(netsuiteInventoryInfoLabel(args.netsuiteInventory));

  return `Inventory: ${inventoryLabels.join(", ")}`;
};


const createCustomEntity = async (
  entity: EntryProps & {
    inventoryNetsuite?: number;
    inventoryShopify?: number;
  },
  sdk: FieldExtensionSDK,
  cma: PlainClientAPI
): Promise<EntryPropsWithCustomMetaData> => {
  const customEntity = entity as EntryPropsWithCustomMetaData;
  customEntity.custom = {};

  // Get information about the swatch if it exists. If it exists, we want
  // to fetch the asset so we have the actual data related to it rather
  // than just the ID.
  const { swatch } = customEntity.fields as Record<
    string,
    undefined | Record<string, Record<string, Record<string, string>>>
  >;
  if (swatch) {
    const localizedSwatch = swatch[sdk.locales.default];
    const assetId = localizedSwatch?.sys?.id;
    if (assetId) {
      await new Promise((r) => setTimeout(r, 250)); // To avoid rate limiting
      await cma.asset.get({ assetId }).then((asset) => {
        customEntity.custom.swatchAsset = asset;
      });
    }
  } else {
    customEntity.custom.swatchAsset = undefined;
  }

  // Get information about the variant images if it exists. We don't need
  // the data for each of the related assets here right now, so instead
  // of fetching for each variant image (and related asset) for each
  // variant, we are just storing the raw link data for our replication.
  const { images } = customEntity.fields;
  customEntity.custom.images = images;

  /**
   * The hero image is the first image of a variant
   * First we need to get the variant entry, then get the image asset
   */
  const heroImageId = images?.[sdk.locales.default].length
    ? images[sdk.locales.default][0].sys.id
    : undefined;

  if (heroImageId) {
    await new Promise((r) => setTimeout(r, 250)); // To avoid rate limiting
    const entry = await cma.entry.get({ entryId: heroImageId });
    const image = await cma.asset.get({
      assetId: entry.fields.image[sdk.locales.default].sys.id
    });

    customEntity.custom.heroImage = image;
  } else {
    customEntity.custom.heroImage = undefined;
  }

  const { inventoryNetsuite, inventoryShopify } = entity;
  customEntity.custom.inventory = inventoryInfo({
    shopifyInventory: inventoryShopify,
    netsuiteInventory: inventoryNetsuite,
  });

  return customEntity;
};

// Return Entrys Current Publication Status
function getEntryStatus(sys: EntryProps['sys']) {
  if (!sys || (sys.type !== 'Entry' && sys.type !== 'Asset')) {
    throw new TypeError('Invalid entity metadata object');
  }
  if (sys.deletedVersion) {
    return 'deleted';
  } else if (sys.archivedVersion) {
    return 'archived';
  } else if (sys.publishedVersion) {
    if (sys.version > sys.publishedVersion + 1) {
      return 'changed';
    } else {
      return 'published';
    }
  } else {
    return 'draft';
  }
}

/** Render the card */
const VariantCard = (
  props: CustomCardRendererProps[0],
  linkActionProps: CustomCardRendererProps[1],
  renderDefaultCard: CustomCardRendererProps[2],
  linkedEntries: undefined | Array<EntryPropsWithCustomMetaData>,
  sdk: FieldExtensionSDK,
  bulkEditIds: Array<string>,
  setBulkEditIds: (id: Array<string>) => void,
  runBulkAction: (
    params: BulkActionParams
  ) => void,
  isBulkActionRunning: boolean,
  updateEntryData: (entity: EntryProps | undefined) => void
) => {
  const entity = props.entity;
  const id = entity.sys.id;
  const skuLocales = (entity.fields as Record<string, Record<string, string>>)
    ?.sku;
  const sku = (skuLocales && skuLocales[sdk.locales.default]) || 'this';

  const badgeColor: { [key: string]: string } = {
    published: 'positive',
    draft: 'warning',
    archived: 'negative',
    deleted: 'negative',
    changed: 'primary'
  };

  let productImage = '';
  let swatchImage = '';
  let inventory = ''
  let description = '';
  let siteActive = false;
  let status = '';

  const entityWithCustomMetadata = linkedEntries?.find((e) => e?.sys.id === id);

  const customOnEdit = async () => {
    try {
      const result = await sdk.navigator.openEntry(
        entityWithCustomMetadata?.sys.id || '',
        { slideIn: { waitForClose: true } }
      );
      updateEntryData(result.entity);
    } catch (error) {
      console.error(
        'Error occured while attempting to open Variant Model: ',
        error
      );
    }
  };

  if (entityWithCustomMetadata) {
    productImage =
      entityWithCustomMetadata.custom?.heroImage?.fields?.file?.[
        sdk.locales.default
      ].url || productImage;

    swatchImage =
      entityWithCustomMetadata.custom?.swatchAsset?.fields?.file?.[
        sdk.locales.default
      ].url || swatchImage;
    
    inventory = entityWithCustomMetadata.custom?.inventory || inventory;

    description = entityWithCustomMetadata.fields?.title?.[sdk.locales.default];
    siteActive =
      entityWithCustomMetadata.fields?.siteActive?.[sdk.locales.default];
    status = getEntryStatus(entityWithCustomMetadata?.sys);
  }

  if (productImage) {
    // Limit image size and quality (the Asset component has transformation capabilities)
    productImage = `${productImage}?q=40&h=120&w=120`;
  }

  if (swatchImage) {
    // Limit image size and quality (the Asset component has transformation capabilities)
    swatchImage = `${swatchImage}?q=40&h=120&w=120`;
  }

  const variantNumber = typeof props.index === 'number' ? `#${props.index + 1}` : ''

  return status === 'deleted' ? (
    <DeletedEntryCard onRemove={props.onRemove} isDisabled={props.isDisabled} />
  ) : (
    <Stack style={{ gap: '0', position: 'relative', alignItems: 'stretch' }}>
      <Checkbox
        className="bombas-variant-checkbox"
        value={id}
        id={`option-${id}`}
        isChecked={bulkEditIds.includes(id)}
        onChange={(e) => {
          const target: HTMLInputElement = e.target as HTMLInputElement;
          if (target.checked === true) {
            setBulkEditIds([...bulkEditIds, id]);
          } else {
            setBulkEditIds(bulkEditIds.filter((i) => i !== id));
          }
        }}
      />
      <div className="bombas-variant-hero-image">
        <Asset type="image" src={productImage} />
      </div>
      <Card
        withDragHandle
        dragHandleRender={props.renderDragHandle}
        padding="none"
        onClick={() => customOnEdit()}
      >
        <Flex flexDirection="column" fullWidth>
          <Stack
            fullWidth
            padding="spacingXs"
            justifyContent="space-between"
            style={{ borderBottom: '1px solid #CFD9E0' }}
          >
            <Flex padding="none" alignItems="center">
              <Text fontColor="gray600">Variant</Text>
              <Text marginLeft="spacingXs" fontColor="gray600">
                {variantNumber}
              </Text>
              {siteActive === false ? (
                <Flex alignItems="center" paddingLeft="spacingM">
                  <Flex>
                    <Tooltip content="This Variant is inactive and will not appear on site. To update this Variant's active status, edit the model for the Variant.">
                      <WarningIcon variant="negative" marginRight="spacingXs" />
                    </Tooltip>
                    <Text fontWeight="fontWeightDemiBold">SITE INACTIVE</Text>
                  </Flex>
                </Flex>
              ) : null}
            </Flex>
            <Stack spacing="spacingXs" alignItems="center">
              <Badge variant={badgeColor[status] as BadgeVariant}>
                {status}
              </Badge>
              <Flex
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Menu>
                  <Menu.Trigger>
                    <IconButton
                      isDisabled={isBulkActionRunning === true}
                      icon={<MoreHorizontalIcon />}
                      aria-label="Toggle menu"
                      size="small"
                      style={{
                        padding: '.25em'
                      }}
                    />
                  </Menu.Trigger>
                  <Menu.List>
                    {props.onEdit && (
                      <Menu.Item key="edit" onClick={() => customOnEdit()}>
                        Edit
                      </Menu.Item>
                    )}
                    {props.onRemove && (
                      <Menu.Item
                        key="remove"
                        onClick={() => props?.onRemove?.()}
                      >
                        Remove
                      </Menu.Item>
                    )}
                    {(props.onMoveTop || props.onMoveBottom) && <MenuDivider />}
                    {props.onMoveTop && (
                      <Menu.Item
                        key="moveToTop"
                        onClick={() => props?.onMoveTop?.()}
                      >
                        Move to top
                      </Menu.Item>
                    )}
                    {props.onMoveBottom && (
                      <Menu.Item
                        key="moveToBottom"
                        onClick={() => props?.onMoveBottom?.()}
                      >
                        Move to bottom
                      </Menu.Item>
                    )}
                  </Menu.List>
                </Menu>
              </Flex>
            </Stack>
          </Stack>
          <Stack fullWidth padding="spacingM" justifyContent="space-between">
            <Stack flexDirection="column" flexWrap="wrap" alignItems="left">
              <Text
                fontSize="fontSizeL"
                fontWeight="fontWeightDemiBold"
                data-test-id="find-here-test"
              >
                {sku}
              </Text>
              <Stack
                flexDirection="column"
                flexWrap="wrap"
                alignItems="left"
                spacing="spacingXs"
              >
                <Text>{description}</Text>
                <Text>{inventory}</Text>
              </Stack>
            </Stack>
            <Box>
              <Asset type="image" src={swatchImage} />
            </Box>
          </Stack>
        </Flex>
      </Card>
      <div className="bombas-variant-menu">
        <Menu>
          <Menu.Trigger>
            <IconButton
              isDisabled={isBulkActionRunning === true}
              variant="secondary"
              icon={<SettingsIcon />}
              aria-label="Toggle menu"
              size="small"
              style={{
                padding: '.25rem'
              }}
            />
          </Menu.Trigger>
          <Menu.List>
            <Menu.SectionTitle>Bulk Edit</Menu.SectionTitle>
            <Menu.Item
                disabled={!swatchImage}
              onClick={() =>
                runBulkAction({
                  action: BulkAction.Replicate,
                  data: {
                    actions: [ReplicateActions.Swatch],
                    fromId: id
                  }
                })
              }
            >
              Replicate Swatch
            </Menu.Item>
            <Menu.Item
              disabled={!productImage}
              onClick={() =>
                runBulkAction({
                  action: BulkAction.Replicate,
                  data: {
                    actions: [ReplicateActions.VariantImages],
                    fromId: id
                  }
                })
              }
            >
              Replicate Images
            </Menu.Item>
            <Menu.Item
              disabled={!swatchImage || !productImage}
              onClick={() =>
                runBulkAction({
                  action: BulkAction.Replicate,
                  data: {
                    actions: [
                      ReplicateActions.Swatch,
                      ReplicateActions.VariantImages
                    ],
                    fromId: id
                  }
                })
              }
            >
              Replicate Images &amp; Swatch
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item disabled>
              <Text fontColor="gray500" fontSize="fontSizeS">
                Data will be replicated to checked
                <br />
                variants from:
              </Text>
            </Menu.Item>
            <Menu.Item disabled>
              <Text fontColor="gray500" fontSize="fontSizeS">
                {sku}
              </Text>
            </Menu.Item>
          </Menu.List>
        </Menu>
      </div>
    </Stack>
  );
};

interface VariantInventory {
  inventoryShopify: number,
  inventoryNetsuite: number,
  id: string,
}

type VariantInventories = Array<VariantInventory>;

/** Field editor for Product.variants */
const ProductVariantsField = ({ sdk, cma }: ProductVariantsFieldProps) => {
  const baseRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const fieldRef = useRef<HTMLUListElement>(null);
  const [isLoadingSearch, setIsLoadingSearch] = useState<Status>(Status.Idle);
  const [isAddingVariant, setIsAddingVariant] = useState<Status>(Status.Idle);
  const [isBulkActionRunning, setIsBulkActionRunning] = useState(false);
  const [items, setItems] = useState<Array<Item>>([]);
  const [variantInventories, setVariantInventories] = useState<VariantInventories>([]);
  const [linkedEntries, setLinkedEntries] = useState<
    undefined | Array<EntryPropsWithCustomMetaData>
  >(undefined);
  const [bulkEditIds, setBulkEditIds] = useState<Array<string>>([]);
  const [marketingData, setMarketingData] = useState({
    gender: sdk.entry.fields.marketed_gender.getValue(),
    age: sdk.entry.fields.marketed_age.getValue()
  });
  /**
   * Gets updated when a bulk action is running (can be updated for more actions)
   * Used as a key in MultipleEntryReferenceEditor
   */
  const [randomNumber, setRandomNumber] = useState(Math.random());
  const hasMarketingData = !!marketingData.gender && !!marketingData.age;

  const findVariantInventories = useCallback(
    async () => {
      const itemProduct = await client(sdk.ids.environment).request(
        gql`
          query getProduct($cmsId: ID!) {
            productByCmsId(id: $cmsId) {
              variants {
                inventoryNetsuite
                inventoryShopify
                id
              }
            }
          }
        `,
        { cmsId: sdk.ids.entry }
      );
      return itemProduct.productByCmsId.variants;
    },
    [sdk.ids.entry]
  )

  useEffect(() => {
    setRandomNumber(Math.random());
  }, [isBulkActionRunning]);

  // Helper function to find all linked entries
  const populateEntries = useCallback(
    async (links: Array<SysLink> = []) => {
      const entryInventories = await findVariantInventories() as VariantInventories;
      setVariantInventories(entryInventories);
      const newLinkedEntries = (await Promise.all(
        links
          .map(
            async (link) =>
              await cma.entry
                .get({ entryId: link.sys.id })
                .then((e) => {
                  const { inventoryNetsuite, inventoryShopify } = entryInventories.find((inventory) => inventory.id === e.sys.id) || {};
                  return createCustomEntity({ ...e, inventoryNetsuite, inventoryShopify }, sdk, cma)
                })
                .catch(() => {
                  // Ignore missing/inaccessible linked entries
                  return null;
                })
          )
          .filter((x) => x)
      )) as Array<EntryPropsWithCustomMetaData>;

      setLinkedEntries(newLinkedEntries);

      return newLinkedEntries;
    },
    [cma, sdk]
  );

  // Helper function to update linked entries on change
  const updateEntryData = useCallback(async (entity: EntryProps | undefined) => {
    const id = entity?.sys.id;

    if (linkedEntries && id) {
      try {
        const entryData = await cma.entry.get({ entryId: id }).then((e) => {
          const { inventoryNetsuite, inventoryShopify } = variantInventories.find((inventory) => inventory.id === e.sys.id) || {};
          return createCustomEntity({...entity, inventoryNetsuite, inventoryShopify, ...e?.sys}, sdk, cma)
        });
        const updatedLinkedEntires = linkedEntries.map((entry) => {
          if (entry?.sys.id === entryData?.sys.id) {
            return entryData;
          }
          return entry;
        })
        setLinkedEntries(updatedLinkedEntires);
      } catch (error) {
        console.error('Error updated variant data: ', error);
      }
    }
  }, [variantInventories]);

  // Populate the linked entries on change
  useEffect(() => {
    const unsubscribe = sdk.field.onValueChanged((links) => {
      populateEntries(links);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const replicate = useCallback(
    async (actions: Array<ReplicateActions>, fromId: string): Promise<void> => {
      // Lock the bulk editing menus until completed so we don't have multiple things going on at once
      setIsBulkActionRunning(true);
      console.info(
        `Starting replication actions (${JSON.stringify(
          actions
        )}) from "${fromId}" to ${JSON.stringify(bulkEditIds)}`
      );

      // Force update entries before doing anything else
      const newLinkedEntries = await populateEntries(sdk.field.getValue());

      try {
        const fromEntry = newLinkedEntries?.find(
          (entry) => entry.sys.id === fromId
        );
        if (!fromEntry) {
          throw new Error(`Invalid fromId (not found): "${fromId}"`);
        }

        /** Replicate swatches */
        const replicateSwatch = async () => {
          console.info('Beginning replication of Swatch');
          const { swatchAsset } = fromEntry.custom;
          if (!swatchAsset) {
            // This shouldn't happen, but just in case!
            throw new Error('No swatch to replicate from');
          }

          for (const entryId of bulkEditIds) {
            // Make sure we don't edit the same exact ID
            if (entryId === fromId) {
              continue;
            }

            console.info(`Replicating Swatch to ${entryId} ...`);
            await new Promise((r) => setTimeout(r, 100)); // To avoid rate limiting

            await patchEntryFieldReferences(
              cma,
              sdk,
              'swatch',
              {
                sys: {
                  type: 'Link',
                  linkType: 'Asset',
                  id: swatchAsset.sys.id
                }
              },
              entryId,
              sdk.locales.default
            );
          }
        };

        /** Replicate variant images */
        const replicateVariantImages = async () => {
          console.info('Beginning replication of VariantImages');
          const { images } = fromEntry.custom;
          if (!images) {
            // This shouldn't happen, but just in case!
            throw new Error('No variant images to replicate from');
          }

          for (const entryId of bulkEditIds) {
            // Make sure we don't edit the same exact ID
            if (entryId === fromId) {
              continue;
            }

            console.info(`Replicating VariantImages to ${entryId} ...`);
            await new Promise((r) => setTimeout(r, 100)); // To avoid rate limiting

            await patchEntryFieldReferences(
              cma,
              sdk,
              'images',
              images,
              entryId
            );
          }
        };

        for (const action of actions) {
          switch (action) {
            case ReplicateActions.Swatch:
              await replicateSwatch();
              break;
            case ReplicateActions.VariantImages:
              await replicateVariantImages();
              break;
            default:
              throw new Error(`Unsupported replication action: "${action}"`);
          }
        }

        await new Promise((r) => setTimeout(r, 500)); // To avoid rate limiting
        await populateEntries(sdk.field.getValue());
      } catch (e) {
        console.error('Error while replicating:', e);
      } finally {
        // When we are done, we set that our bulk actions are set to false regardless of the outcome
        setIsBulkActionRunning(false);
      }
    },
    [bulkEditIds, cma, sdk]
  );

  const toggleActiveStatus = useCallback(async (type: string) => {
    // Lock the bulk editing menus until completed so we don't have multiple things going on at once
    setIsBulkActionRunning(true);

    // Force update entries before doing anything else
    await populateEntries(sdk.field.getValue());

    try {
      for (const entryId of bulkEditIds) {
        await new Promise((r) => setTimeout(r, 100)); // To avoid rate limiting

        const activeStatus = type === ToggleActiveStatusType.Active ? true : false;

        const entry = await cma.entry.get({ entryId });
        entry.fields.siteActive[sdk.locales.default] = activeStatus

        await cma.entry.update({ entryId }, entry);
      }

      await new Promise((r) => setTimeout(r, 100)); // To avoid rate limiting
      await populateEntries(sdk.field.getValue());
    } catch (error) {
      console.error('Error while setting active status: ', error);
    } finally {
      setIsBulkActionRunning(false);
    }
  }, [bulkEditIds, cma, sdk])

  const bulkPublish = useCallback(async () => {
    setIsBulkActionRunning(true);

    try {
      for (const entryId of bulkEditIds) {
        const entry = linkedEntries?.find((e) => e.sys.id === entryId);
        if (!entry) {
          continue;
        }

        await cma.entry.publish({ entryId }, entry);
      }

      await populateEntries(sdk.field.getValue());
    } catch (e) {
      console.error('Error while publishing:', e);
    } finally {
      setIsBulkActionRunning(false);
    }
  }, [bulkEditIds, cma, populateEntries, linkedEntries, sdk]);
  const runBulkAction = useCallback(
    async (params: BulkActionParams) => {
      const action = params.action;
      switch (action) {
        case BulkAction.Replicate:
          if (!params.data) {
            throw new Error('Missing data, can not replicate');
          }
          return await replicate(params.data.actions, params.data.fromId);
        case BulkAction.Publish:
          return await bulkPublish();
        case BulkAction.ToggleActiveStatus:
          return await toggleActiveStatus(params.data.type)
        default:
          throw new Error(`Unsupported action type: "${action}"`);
      }
    },
    [bulkPublish, replicate, toggleActiveStatus]
  );

  const debouncedQuery = useMemo(
    () =>
      debounce((value) => {
        client(sdk.ids.environment)
          .request(
            gql`
              query itemSearchRaw($query: String!) {
                itemSearchRaw(query: $query)
              }
            `,
            { query: value }
          )
          .then((res) => {
            const newFilteredItems = (res?.itemSearchRaw || []).filter(
              (item: Item) => {
                const foundEntry =
                  linkedEntries &&
                  linkedEntries.find((entry) => {
                    if (entry?.fields?.sku) {
                      if (typeof entry?.fields?.sku === 'string') {
                        return entry.fields.sku === item.sku;
                      } else {
                        return (
                          entry.fields.sku[sdk.locales.default] === item.sku
                        );
                      }
                    }

                    return false;
                  });

                return typeof foundEntry === 'undefined';
              }
            );
            setItems(newFilteredItems);
            setIsLoadingSearch(Status.Success);
          })
          .catch((err) => {
            console.error(err);
            setIsLoadingSearch(Status.Failure);
          });
      }, 300),
    [sdk.locales.default, linkedEntries]
  );
  const handleInputValueChange = (value: string | Item) => {
    if (value !== '') {
      setIsLoadingSearch(Status.Loading);
      debouncedQuery(value);
    }
  };
  const handleSelectItem = async (selectedItem: Item) => {
    setIsAddingVariant(Status.Loading);

    // Remove selected item from list
    setItems((prevItems) =>
      prevItems.filter((item: Item) => item.sku !== selectedItem.sku)
    );

    // Check to see if the variant exists before creating
    const possibleEntries = await cma.entry.getMany({
      query: {
        content_type: 'variant',
        'fields.sku': selectedItem.sku,
        'fields.marketed_gender': marketingData.gender,
        'fields.marketed_age': marketingData.age
      }
    });

    // If it exists, grab the first instance of it. If not, create a new one.
    const entry =
      possibleEntries?.total > 0
        ? possibleEntries.items[0]
        : await cma.entry.create(
            {
              contentTypeId: 'variant'
            },
            {
              fields: {
                sku: {
                  [sdk.locales.default]: selectedItem.sku
                },
                marketed_gender: {
                  [sdk.locales.default]: marketingData.gender
                },
                marketed_age: {
                  [sdk.locales.default]: marketingData.age
                },
                title: {
                  [sdk.locales.default]: generateVariantTitle(selectedItem)
                }
              }
            }
          );

    // Publish the variant
    await cma.entry.publish({ entryId: entry.sys.id }, entry);

    const existingFields = sdk.field.getValue() || [];
    // Merge the existing variants with the newly added variant
    sdk.field.setValue([
      {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: entry.sys.id
        }
      },
      ...existingFields,
    ]);

    setIsAddingVariant(Status.Success);
  };

  // Listen for changes to `marketed-gender/age` field.
  useEffect(() => {
    const handleChange = (key: string) => (value: string) => {
      setMarketingData((prev) => ({ ...prev, [key]: value }));
    };
    const unsubscribeGender = sdk.entry.fields.marketed_gender.onValueChanged(
      handleChange('gender')
    );
    const unsubscribeAge = sdk.entry.fields.marketed_age.onValueChanged(
      handleChange('age')
    );

    return () => {
      unsubscribeGender();
      unsubscribeAge();
    };
  }, [sdk.entry.fields.marketed_gender, sdk.entry.fields.marketed_age]);

  useDynamicHeight(sdk, { baseRef, fieldRef });

  const handleAction = () => false;

  const totalLinkedVariants = typeof linkedEntries?.length === 'number' ? linkedEntries.length : 0;

  return (
    <div ref={baseRef}>
      {isBulkActionRunning && (
        <div className="bombas-loading-overlay">
          Working...
          <span>
            {sillyQuotes[Math.floor(Math.random() * sillyQuotes.length)]}
          </span>
        </div>
      )}
      <Stack flexDirection="column" fullWidth={true}>
        {!hasMarketingData && (
          <Note
            variant="warning"
            style={{ width: '100%' }}
            title="Missing Marketing Gender / Age"
          >
            Set a marketing gender and age to add variants.
          </Note>
        )}
        {totalLinkedVariants >= VARIANTS_LIMIT && hasMarketingData && (
          <Note variant='warning' style={{ width: '100%' }} title='Maximum Number of Variants Met'>
            To add more variants, remove site inactive variants.
          </Note>
        )}
        <Card>
          <Paragraph>
            <Text>
              Search for variants to add to the product{' '}
              {hasMarketingData && (
                <>
                  (Gender:
                  <b> {marketingData.gender}</b>, Age:
                  <b> {marketingData.age}</b>)
                </>
              )}
            </Text>
          </Paragraph>
          <Stack flexDirection='column' alignItems='flex-start' spacing='spacingXs'>
            <Autocomplete
              placeholder="SKU, Style Number, Name, or Color"
              listMaxHeight={300}
              listRef={fieldRef}
              inputRef={inputRef}
              onInputValueChange={handleInputValueChange}
              onSelectItem={handleSelectItem}
              isLoading={isLoadingSearch === Status.Loading}
              isDisabled={!hasMarketingData || !(totalLinkedVariants < VARIANTS_LIMIT)}
              listWidth="full"
              clearAfterSelect={false}
              closeAfterSelect={false}
              noMatchesMessage="No items found, try being more specific."
              items={items}
              itemToString={() => inputRef.current?.value || ''} // Preserve the search term after an item is selected so additional items can be added.
              renderItem={renderVariantItem}
            />
            <Text marginLeft='spacingXs' fontSize="fontSizeS" fontColor='gray700' as='i'>A maximum of {VARIANTS_LIMIT} variants can be linked to a product. If more than {VARIANTS_LIMIT} variants are linked, only the first {VARIANTS_LIMIT} variants will be synced to Shopify.</Text>
          </Stack>
        </Card>
        {!linkedEntries ||
          (isAddingVariant === Status.Loading && (
            <Card>
              <SkeletonContainer
                svgHeight="46px"
                ariaLabel="Loading variant(s)"
              >
                <SkeletonBodyText numberOfLines={3} />
              </SkeletonContainer>
            </Card>
          ))}
        {isAddingVariant === Status.Failure && (
          <Text fontColor="red500">No results found.</Text>
        )}
        <Flex fullWidth justifyItems="flex-start">
          <SectionHeading marginBottom='none'>Total Variants: {totalLinkedVariants}</SectionHeading>
        </Flex>
        {linkedEntries !== undefined && (
          <div style={{ width: '100%', userSelect: 'none' }}>
            <MultipleEntryReferenceEditor
              viewType="card"
              sdk={sdk}
              hasCardEditActions
              isInitiallyDisabled={false}
              parameters={{
                instance: {
                  showCreateEntityAction: hasMarketingData && totalLinkedVariants < VARIANTS_LIMIT,
                  showLinkEntityAction: false
                }
              }}
              renderCustomCard={(...props) =>
                VariantCard(
                  ...props,
                  linkedEntries,
                  sdk,
                  bulkEditIds,
                  setBulkEditIds,
                  runBulkAction,
                  isBulkActionRunning,
                  updateEntryData
                )
              }
              /**
               * `onAction` is needed to force the UI to update (checkbox state)
               */
              onAction={handleAction}
              /**
               * When bulk actions run this is updated to force UI to refresh
               */
              key={randomNumber}
            />
            <Flex>
              <Button
                size="small"
                style={{ marginTop: '20px', marginRight: '5px' }}
                onClick={() => setBulkEditIds([])}
                isDisabled={!bulkEditIds.length}
              >
                Clear
              </Button>
              <Button
                size="small"
                style={{ marginTop: '20px', marginRight: '5px' }}
                onClick={() =>
                  setBulkEditIds(linkedEntries.map((e) => e?.sys.id))
                }
              >
                Select All
              </Button>
              <Button
                size="small"
                style={{ marginTop: '20px', marginRight: '5px' }}
                onClick={() => window.location.reload()}
              >
                Force Refresh
              </Button>
            </Flex>
            <Flex>
              <Button
                size="small"
                style={{ marginTop: '20px', marginRight: '5px' }}
                onClick={() => runBulkAction({ action: BulkAction.ToggleActiveStatus, data: { type: ToggleActiveStatusType.Active }})}
                isDisabled={!bulkEditIds.length}
              >
                Mark Active
              </Button>
              <Button
                size="small"
                style={{ marginTop: '20px', marginRight: '5px' }}
                onClick={() => runBulkAction({ action: BulkAction.ToggleActiveStatus, data: { type: ToggleActiveStatusType.Inactive }})}
                isDisabled={!bulkEditIds.length}
              >
                Mark Inactive
              </Button>
              <Button
                size="small"
                style={{ marginTop: '20px', marginRight: '5px' }}
                onClick={() => runBulkAction({ action: BulkAction.Publish})}
                isDisabled={!bulkEditIds.length}
              >
                Publish Variants
              </Button>
            </Flex>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default ProductVariantsField;
