import { useEffect, useState } from 'react';
import { client, gql } from '../services/graphql';
import ReferenceAttributesField from '../ReferenceAttributeInput/ReferenceAttributeInput';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { Text, Stack } from '@contentful/f36-components';

type ReferenceAttributes = Record<string, Array<string>>;

const VALID_ATTRIBUTES: ReferenceAttributes = {
  size: ['xs', 's', 'm', 'l', 'xl', '1x', '2x', '3x'],
  has_pocket: ['true', 'false'],
  has_gripper: ['true', 'false'],
}

const FilterOptionReferenceAttributesField = ({
  sdk
}: {
  sdk: FieldExtensionSDK;
}) => {
  const [validReferenceAttributes, setValidReferenceAttributes] = useState(VALID_ATTRIBUTES);

  useEffect(() => {
    client(sdk.ids.environment)
      .request(
        gql`
          query getReferenceAttributes {
            referenceAttributes {
              sockWeight
              sockHeight
              neckCollarType
              endUse
              category
              cut
              sleeveType
              subclass
              color
              patternGroup
              collection
              merchSilo
              primaryMaterial
              percentOff
              prepackSize
              age
              gender
              elevated
              waistRise
              uwCoverage
            }
          }
        `,
        {}
      )
      .then(({ 
        referenceAttributes: {
          sockWeight,
          sockHeight,
          neckCollarType,
          endUse,
          category,
          cut,
          sleeveType,
          subclass,
          color,
          patternGroup,
          collection,
          merchSilo,
          primaryMaterial,
          percentOff,
          prepackSize,
          age,
          gender,
          elevated,
          waistRise,
          uwCoverage,
        }
      }) => {
        setValidReferenceAttributes((v) => ({
          ...v,
          sock_weight: sockWeight,
          sock_height: sockHeight,
          neck_collar_type: neckCollarType,
          end_use: endUse,
          category,
          cut,
          sleeve_length: sleeveType,
          sub_class: subclass,
          color,
          pattern_group: patternGroup,
          collection,
          merch_silo: merchSilo,
          material: primaryMaterial,
          percent_off: percentOff,
          prepack_size: prepackSize,
          age,
          gender,
          elevated,
          waist_rise: waistRise,
          uw_coverage: uwCoverage,
        })
      )}
    );
  }, []);

  return (
    <Stack flexDirection="column" spacing="spacingS" alignItems="left">
      <ReferenceAttributesField sdk={sdk} attributes={validReferenceAttributes} />
      <Text fontColor="gray800" fontSize="fontSizeS">
        Reference attributes are used to link a filter option to attributes on a
        product.
      </Text>
      <Text as="i" fontColor="gray500">
        The combination of all rows will make this filter active.
      </Text>
    </Stack>
  );
};

export default FilterOptionReferenceAttributesField;
