import React, { useEffect, useState } from 'react';
import ReferenceAttributesField from '../ReferenceAttributeInput/ReferenceAttributeInput';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { Stack, Text } from '@contentful/f36-components';
import { client, gql } from '../services/graphql';

export const VALID_ATTRIBUTES = {};

const BreadcrumbResourceReferenceAttributes = ({
  sdk
}: {
  sdk: FieldExtensionSDK;
}) => {
  const [validReferenceAttributes, setValidReferenceAttributes] = useState(VALID_ATTRIBUTES);

  useEffect(() => {
    client(sdk.ids.environment)
      .request(
        gql`
          query getReferenceAttributes {
            referenceAttributes {
              sockHeight
              neckCollarType
              category
              cut
              sleeveType
              gender
              elevated
            }
          }
        `,
        {}
      )
      .then(({ 
        referenceAttributes: {
          sockHeight,
          neckCollarType,
          category,
          cut,
          sleeveType,
          gender,
          elevated,
        }
      }) => {
        setValidReferenceAttributes((v) => ({
          ...v,
          height: sockHeight,
          neckline: neckCollarType,
          category,
          cut,
          sleeveLength: sleeveType,
          gender,
          elevated,
        })
      )}
    );
  }, []);

  return (
    <Stack flexDirection="column" spacing="spacingS" alignItems="left">
      <ReferenceAttributesField sdk={sdk} attributes={validReferenceAttributes} />
      <Text>
        Reference attributes are used to connect a breadcrumb to attributes on a
        product.
      </Text>
      <Text as="i" fontColor="gray500">
        Include as much information as possible for automatic breadcrumb
        generation to be effective. If the breadcrumb is for a Women's Calf
        Sock, the appropriate tags for gender, category, and sock_height should
        be added. If a breadcrumb is for Women's Socks, the appropriate tags for
        gender and category should be added.
      </Text>
    </Stack>
  );
};

export default BreadcrumbResourceReferenceAttributes;
