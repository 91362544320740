import React, { useState, useEffect } from 'react';
import {
  MultipleEntryReferenceEditor,
  CombinedLinkActions
} from '@contentful/field-editor-reference';
import { FieldExtensionSDK, ContentType } from '@contentful/app-sdk';
import { Entry, PlainClientAPI } from 'contentful-management';
import { Box, Note, Text } from '@contentful/f36-components';
import {
  FieldState,
  SortDirection
} from './DynamicProductGrid.referenceAttributes';

interface DynamicProductGridItemsFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

const getContentTypeIds = (contentTypes: ContentType[]) =>
  contentTypes.map((ct) => ct.sys.id);

const contentTypeNames: Record<string, string> = {
  product: 'Product',
  productCard: 'Product Wrapper',
  contentCard: 'Content Card',
  imageCard: 'Image Card',
  textCard: 'Text Card'
};

const mapContentTypeName = (contentType: string) => {
  return contentTypeNames[contentType] || contentType;
};

const fetchAllEntries = async (cma: PlainClientAPI, entryIds: string[]) => {
  const entries = await Promise.all(entryIds.map(id => 
    cma.entry.get({ entryId: id })
  ));

  return entries;
};


const DynamicProductGridItemsField = ({
  sdk,
  cma
}: DynamicProductGridItemsFieldProps) => {
  const [refAttributeConfig, setRefAttributeConfig] = useState<FieldState>(
    sdk.entry.fields.referenceAttributes.getValue()
  );
  const [itemCounts, setItemCounts] = useState<Record<string, number>>({});

  // Listen for changes to `referenceAttributes` field.
  useEffect(() => {
    const unsubscribe = sdk.entry.fields.referenceAttributes.onValueChanged(
      (value) => {
        setRefAttributeConfig(value);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [sdk.entry.fields.referenceAttributes, sdk.field.onValueChanged]);

  useEffect(() => {
    const updateItemCounts = async () => {
      const entries = await sdk.entry.fields.items.getValue();
  
      if (entries) {
        const entryIds = entries.map((entry: Entry) => entry.sys.id);
        const detailedEntries = await fetchAllEntries(cma, entryIds);
        const counts: Record<string, number> = {};
    
        detailedEntries.forEach((entry) => {
          const contentType = entry.sys.contentType.sys.id;
          counts[contentType] = (counts[contentType] || 0) + 1;
        });
    
        setItemCounts(counts);
      }
    };

    updateItemCounts();
  }, [sdk.entry.fields.items, cma]);

  sdk.window.startAutoResizer();

  return (
    <>
      <Box marginBottom="spacingS">
        {Object.entries(itemCounts).map(([contentType, count], index, array) => (
          <Text key={contentType} fontWeight="fontWeightMedium" fontColor='gray500'>
            {mapContentTypeName(contentType)}: {count}
            {index < array.length - 1 && ', '}
          </Text>
        ))}
      </Box>
      {!refAttributeConfig && (
        <Box marginBottom="spacingS">
          <Note variant="neutral">
            You must set reference attributes before adding additional items.
          </Note>
        </Box>
      )}
      <div style={{userSelect: 'none'}}>
        <MultipleEntryReferenceEditor
          sdk={sdk}
          viewType="link"
          hasCardEditActions
          hasCardMoveActions
          isInitiallyDisabled
          parameters={{
            instance: { showCreateEntityAction: true, showLinkEntityAction: true }
          }}
          renderCustomActions={(props) => (
            <CombinedLinkActions
              {...props}
              isDisabled={!refAttributeConfig}
              onLinkExisting={async () => {
                if (props.entityType === 'Entry') {
                  // Dynamic grids are generated and synchronized based on their configured attributes.
                  // This prevents a user from manually adding additional Product to the grid.
                  const entity = await sdk.dialogs.selectSingleEntry<Entry>({
                    locale: sdk.field.locale,
                    contentTypes: getContentTypeIds(
                      props.contentTypes.filter(
                        (c) =>
                          c.sys.id !== 'product'
                      )
                    )
                  });
                  if (entity) {
                    props.onLinkedExisting([entity]);
                  }
                }
              }}
              contentTypes={props.contentTypes.filter(
                (c) => c.sys.id !== 'product'
              )}
            />
          )}
          renderCustomCard={(props, _, renderDefaultCard) => {
            const itemNumber = typeof props.index === 'number' ? `#${props.index + 1}` : '';
            const titleField = props.contentType?.name;
            const updatedTitle = `${titleField} ${itemNumber}`;

            return renderDefaultCard({
              ...props,
              contentType: props.contentType ? {
                ...props.contentType,
                name: updatedTitle,
              } : undefined,
              // Due to the nature of dynamic grids, Product & ProductCard cannot be removed from the grid
              // unless we introduce a disallow-list mechanism to persist removed cards.
              onRemove:
                props?.contentType?.sys.id === 'product'
                  ? undefined
                  : props.onRemove,
              renderDragHandle:
                refAttributeConfig?.sort === SortDirection.BEST_SELLING &&
                (props?.contentType?.sys.id === 'product' ||
                  props.contentType?.sys.id === 'productCard')
                  ? undefined
                  : props.renderDragHandle
            });
          }}
        />
      </div>
    </>
  );
};

export default DynamicProductGridItemsField;
